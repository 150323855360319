var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.sent)?[_vm._t("sent",function(){return [_c('div',{staticClass:"rounded-md p-4"},[_c('p',{staticClass:"text-bg-gray-700 text-lg text-center mt-12"},[_vm._v("\n          Thank you, your message has been sent. We'll be in touch shortly.\n        ")])])]})]:_vm._e(),_vm._v(" "),(!_vm.sent)?_c('div',{staticClass:"grid grid-cols-1 gap-y-6",attrs:{"role":"form"}},[_c('div',[_c('label',{staticClass:"text-gray-700 block text-sm font-medium leading-5",attrs:{"for":"name"}},[_vm._v("Name")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mt-1 relative rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:_vm.inputClasses(errors[0], ''),attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_vm._v(" "),(errors[0])?_c('error-icon'):_vm._e()],1),_vm._v(" "),(errors[0])?_c('p',{staticClass:"mt-2 text-sm text-red-600"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")]):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('div',[_c('label',{staticClass:"text-gray-700 block text-sm font-medium leading-5",attrs:{"for":"email"}},[_vm._v("Email")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email","debounce":500},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mt-1 relative rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:_vm.inputClasses(errors[0], ''),attrs:{"id":"email","type":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_vm._v(" "),(errors[0])?_c('error-icon'):_vm._e()],1),_vm._v(" "),(errors[0])?_c('p',{staticClass:"mt-2 text-sm text-red-600"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")]):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('div',[_c('label',{staticClass:"text-gray-700 block text-sm font-medium leading-5",attrs:{"for":"message"}},[_vm._v("Message")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mt-1 relative rounded-md shadow-sm"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],class:_vm.inputClasses(errors[0], 'form-textarea'),attrs:{"id":"message","rows":"4"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_vm._v(" "),(errors[0])?_c('error-icon'):_vm._e()],1),_vm._v(" "),(errors[0])?_c('p',{staticClass:"mt-2 text-sm text-red-600"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")]):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('div',[_c('span',{staticClass:"w-full inline-flex rounded-md shadow-sm"},[_c('button',{staticClass:"bg-wiq-green shadow border border-gray-50 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white transition ease-in-out duration-150",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.postForm)}}},[_vm._v("\n            Send\n            "),(_vm.loading)?_c('spinner',{attrs:{"addtl-class":"text-white ml-2"}}):_vm._e()],1)])]),_vm._v(" "),(_vm.errorMessage)?_c('div',{staticClass:"text-sm text-red-600"},[_vm._v("\n      "+_vm._s(_vm.errorMessage)+"\n    ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('recaptcha',{ref:"recaptcha",staticStyle:{"margin-bottom":"16px","margin-top":"16px"},attrs:{"captcha-action":_vm.captchaAction,"captcha-site-key":_vm.captchaSiteKey,"captcha-v3-site-key":_vm.captchaV3SiteKey}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }